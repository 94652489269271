<template>
  <div>
    <b-row>
      <b-col
        v-if="accessPermissions('users.show')"
        sm="12"
      >
        <b-card-code
          title="Actualizar Perfil"
        >
          <b-card-body>
            <FormCreateComponent />
          </b-card-body>
        </b-card-code>
      </b-col>
<!--      <b-col-->
<!--        v-if="accessPermissions('users.change.photo')"-->
<!--        sm="12"-->
<!--      >-->
<!--        <b-card-code-->
<!--          title="Cambio de avatar"-->
<!--        >-->
<!--          <b-card-body>-->
<!--            <FormAvatarComponent />-->
<!--          </b-card-body>-->
<!--        </b-card-code>-->
<!--      </b-col>-->
      <b-col
        v-if="accessPermissions('users.password')"
        sm="12"
      >
        <b-card-code
          title="Cambio de contraseña"
        >
          <b-card-body>
            <FormPasswordComponent
              root="profile"
            />
          </b-card-body>
        </b-card-code>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow,
  BCol,
  BCardBody,
} from 'bootstrap-vue'
import FormCreateComponent from './components/FormCreateComponent.vue'
import FormAvatarComponent from './components/FormAvatarComponent.vue'
import FormPasswordComponent from './components/FormPasswordComponent.vue'
import permissions from '@/libs/permissions'

export default {
  name: 'ProfileEditPage',
  components: {
    BRow,
    BCol,
    BCardCode,
    BCardBody,
    FormCreateComponent,
    FormAvatarComponent,
    FormPasswordComponent,
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
  },
}
</script>

<style scoped>

</style>
