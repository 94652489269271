<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Avatar"
            label-for="file"
          >
            <validation-provider
              #default="{ errors }"
              name="avatar"
              rules="required"
            >
              <b-form-file
                v-model="file"
                placeholder="Elija un archivo o suéltelo aquí..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-if="accessPermissions('users.change.photo')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            :to="{ name: 'main' }"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BFormFile,
} from 'bootstrap-vue'
import { required } from '@validations'
import ProfileService from '../services/ProfileService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FormAvatarComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormFile,
  },
  data() {
    return {
      file: null,
      required,
    }
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.file = null
      this.$refs.formValidation.reset()
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const id = JSON.parse(localStorage.getItem('userData'))
          const formData = new FormData()
          formData.append('photo', this.file)

          if (this.accessPermissions('users.change.photo')) {
            ProfileService.handleChangeAvatar(id.id, formData).then(response => {
              if (response.data.data) {
                this.clearForm()
                this.swal('La foto ha sido actualizada correctamente', 'success')
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>