<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre"
            label-for="first_name"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-input
                id="first_name"
                v-model="first_name"
                :state="errors.length > 0 ? false:null"
                placeholder="Nombre"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Apellido"
            label-for="last_name"
          >
            <validation-provider
              #default="{ errors }"
              name="apellido"
              rules="required"
            >
              <b-form-input
                id="last_name"
                v-model="last_name"
                :state="errors.length > 0 ? false:null"
                placeholder="Apellido"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                v-model="emailValue"
                :state="errors.length > 0 ? false:null"
                type="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="DNI"
            label-for="dni_profile"
          >
            <b-form-input
              id="dni_profile"
              v-model="dni_profile"
              placeholder="DNI"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Teléfono"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="phone"
              placeholder="Teléfono"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-if="accessPermissions('users.update.profile')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            :to="{ name: 'main' }"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ProfileService from '../services/ProfileService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FormCreateComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      first_name: '',
      last_name: '',
      emailValue: '',
      dni_profile: '',
      phone: '',
      file: null,
      required,
      email,
    }
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.first_name = ''
      this.last_name = ''
      this.emailValue = ''
      this.dni_profile = ''
      this.phone = ''
      this.$refs.formValidation.reset()
    },
    clearAvatar() {
      this.file = null
    },
    async onHandleEdit() {
      if (this.accessPermissions('users.show')) {
        const id = JSON.parse(localStorage.getItem('userData'))

        await ProfileService.handleEdit(id.id).then(response => {
          this.first_name = response.data.data.profile.first_name
          this.last_name = response.data.data.profile.last_name
          this.emailValue = response.data.data.email
          this.dni_profile = response.data.data.profile.dni_profile
          this.phone = response.data.data.profile.phone
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const id = JSON.parse(localStorage.getItem('userData'))
          const data = {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.emailValue,
            dni_profile: this.dni_profile,
            phone: this.phone,
          }

          if (this.accessPermissions('users.update.profile')) {
            ProfileService.handleUpdate(id.id, data).then(response => {
              if (response.data.data) {
                this.clearForm()
                this.swal('El usuario ha sido actualizado correctamente', 'success')
                this.onHandleEdit()
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>